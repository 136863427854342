export const SETTING_INFORMATION = {
  path: "scope-2-renewables-performance",
  name: "Renewable Performance",

  SCOPE_1_AND_3: {
    path: "scope-1-and-scope-3-emissions",
    name: "Scope 1 and Scope 3 Emissions",
  },
  SCOPE13E_INPUT_MANUAL: {
    path: "scope-1-and-scope-3-emissions/input-data-manual",
    name: "S13E Input Data Manual",
  },
  SCOPE_2_RENEWABLES_PERFORMMANACE: {
    path: "scope-2-renewables-performance",
    name: "Scope 2 Renewables Performance",
  },
  SCOPE_2_INPUT_PPA: {
    path: "input-PPA-and-RECs",
    name: "Input PPA and RECs",
  },
  S2RP_INPUT_MANUAL: {
    path: "input-data-manual",
    name: "S2RP Input Data Manual",
  },
  SCOPE_2_EMISSION_TARGETS: {
    path: "scope-2-emission-targets",
    name: "Scope 2 Emission Targets",
  },
  YOUR_GREEN_OPTIONS: {
    path: "your-green-options",
    name: "Your Green Options",
    CONTACT_Us: {
      path: "contact-us",
      name: "Contact Us",
    },
    SELECT_STANDARD: {
      path: "select-standard",
      name: "Select Standard",
      BUY_RECS: {
        path: "buy-recs",
        name: "Buy RECs",
      },
      BUY_ORDER: {
        path: "buy-order",
        name: "Buy Order",
      },
    },
  },
  SETTING_BASELINE: {
    path: "setting-baseline",
    name: "Setting Baseline",
  },
};

export const DASHBOARD = {
  path: "",
  name: "Dashboard",

  SUSTAINABILITY_ACHIEVEMENTS: {
    path: "performance-world-map",
    name: "RE Performance World Map",
  },
  PERFORMANCE_OVERVIEW: {
    path: "performance-overview",
    name: "Performance Overview",
  },
  FACILITIES_MONITORING: {
    path: "facilities-monitoring",
    name: "Facilities Monitoring",
    FACILITIES_MONITORING_ID: {
      path: ":facility_id",
      name: "Facilities Monitoring Details",
    },
  },
};
export const FACILITIES_MONITORING = {
  path: "facilities-monitoring",
  name: "Facilities Monitoring",
  FACILITIES_MONITORING_ID: {
    path: ":facility_id",
    name: "Facilities Monitoring Details",
  },
  FACILITIES_CREATE: {
    path: "create-facility-monitoring",
    name: "Create New Facility",
  },
  FACILITIES_EDIT: {
    path: "create-facility-monitoring",
    name: "Create New Facility",
  },
};

export const RENEWABLE_ENERGY_TARGET_MANAGEMENT = {
  path: "renewable-energy-target-management",
  name: "Renewable Energy Target Management",

  PUBLISH_NEW_TARGET: {
    path: "publish-new-target",
    name: "Publish New Target",
  },
};

export const USER = {
  USER_CREATE: { path: "create-user", name: "Add New User" },
  USER_OVERVIEW: {
    path: "user",
    name: "User Overview",
  },
};

export const RENEWABLE_OPTIONS = {
  path: "renewable-options",
  name: "Renewable Options",

  BUY_RECS: {
    path: "buy-recs",
    name: "Buy RECs",
  },
  RENEWABLE_OPTION_ID: {
    path: ":renewable_option_id/detais",
    name: "Renewable Option Details",
  },
};
export const SUPPLY_CHAIN_MANAGEMENT = {
  path: "supply-chain-management",
  name: "Supply Chain Management",

  INVITE_PARTNER: {
    path: "invite-partner",
    name: "Invite Partner",
  },
};
export const COMPANY_ACCOUNT_MANAGEMENT = {
  path: "company-account-management",
  name: "Company Account Management",
};

export const BRAND_OWNER_INFORMATION = {
  path: "brand-owner-information",
  name: "Brand Owner Information",

  SEND_INVITATION: {
    path: "send-invitation",
    name: "Send Invitation",
  },

  SINGLE_INVITATION: {
    path: "send-invitation/single",
    name: "Single Invitation",
  },

  BULK_INVITATION: {
    path: "send-invitation/bulk",
    name: "Bulk Invitation",
  },
};
