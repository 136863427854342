export enum STATUS_TENANT {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum STATUS_TIER_ONE {
  Pending = 0,
  Approved = 1,
  Sent = 2,
  Removed = 3,
}

export enum STATUS_BRAND_OWNER {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
}

export enum STATUS_INVITATION {
  Inactive = 0,
  Activated = 1,
  Sent = 2,
  Pending = 3,
  Approved = 4,
  Removed = 5
}